/** @format */

export const our_services = [
  {
    name: "Staff Augmentation",
    count: "01",
    description:
      "We let you hire the expertise your team needs to scale productivity, without the hassles of a long recruitment process",
  },
  {
    name: "Bespoke Web and Mobile Apps",
    count: "02",
    description:
      " We ensure rich customer experiences by developing personalized applications that fully utilize power and flexibility",
  },
  {
    name: "Custom Software Solutions",
    count: "03",
    description:
      "We surpass at providing high-tech enterprise software services that are highly-responsive & outstandingly designed",
  },
];

export const header_options = [
  { name: "Home", route: "home" },
  { name: "Company", route: "company" },
  { name: "Services", route: "services" },
  { name: "Portfolio", route: "portfolio" },
  { name: "Blog", route: "blogs" },
  { name: "Careers", route: "careers" },
  { name: "Startup", route: "start-up" },
  { name: "Hire Developer", route: "hire-developer" },
];
export const build_or_buy = {
  name: "Build or Buy",
  heading: "Fixed Price Project",
  details:
    "Our fixed price paradigm best suits customers who wish to accomplish projects with limited scope and scalability with clear, predetermined and constant requirements.",
};

export const our_achievements = [
  {
    count: "9+",
    heading: "Years of Growth 🚀",
    details:
      "From x to a full development team with steady growth and value delivery",
  },
  {
    count: "10+",
    heading: "Industries 💼",
    details:
      "We’ve developed digital products for the hottest industries out there",
  },
  {
    count: " 500+",
    heading: "Projects Delivered ✅",
    details:
      "We ensure that your project has the tools and processes needed to succeed",
  },
  {
    count: "90%",
    heading: "Client Retention 🤩",
    details:
      "We gain customer trust with excellent communication and high-quality services",
  },
];
export const leaderShips = [
  {
    name: "Yasir Aziz",
    image: "images/yasir.jpg",
    designation: "CEO and Founder",
    color: "#FF5D52",
    linkedin: "https://www.linkedin.com/in/muhammadyasir/",
  },
  {
    name: "Fawad Naeem",
    image: "images/img_image50.png",
    designation: "Technology Advisor",
    color: "#FFCC01",
    linkedin: "https://www.linkedin.com/in/fawadnaeem/",
  },
  {
    name: "Usman Saeed",
    image: "images/usman.jpg",
    designation: "Engagement Manager | Project Manager",
    color: "#56DE7D",
    linkedin: "https://www.linkedin.com/in/musmansaeed91/",
  },
  // {
  //   name: "Prince D. Levine",
  //   image: "images/prince.jpg",
  //   designation: " U.S Relations Partner",
  //   color: "#A230C3",
  //   linkedin: "https://www.linkedin.com/in/prince-d-levine-468902150/",
  // },
  {
    name: "SA Shahzad",
    image: "images/shahzad.jpg",
    designation: "Director Sales & Marketing",
    color: "#56DE7D",
    linkedin: "https://www.linkedin.com/in/ahmad-shahzad-24636232/",
  },
  {
    name: "Shahzaib Saleem",
    image: "images/shahzab.jpg",
    designation: "Big Data Analytics | E-Commerce Expert",
    color: "#00B7E9",
    linkedin: "https://www.linkedin.com/in/shahzaib-saleem-b094347/",
  },
  {
    name: "Sohail Mohabbat Ali",
    image: "images/sohail.jpg",
    designation: "Senior Software Architect",
    color: "#E3285F",
    linkedin: "https://www.linkedin.com/in/sohail-mohabbat-ali-32b2242b/",
  },

  {
    name: "Muhammad Anser",
    image: "images/anser.jpg",
    designation: "Software Architect",
    color: "#FFCC01",
    linkedin: "https://www.linkedin.com/in/muhammad-anser/",
  },
];

export const outsource_development = {
  name: "Outsourced Development",
  heading: "Dedicated Teams",
  details:
    "Dedicated Team model is ideal for customers looking for a prolonged business association for executing high-end and complex projects from start to finish.",
};

export const services = [
  {
    name: "Mobile Development",
    route: "service-details?name=Mobile Development",
  },
  { name: "Web Applications", route: "service-details?name=Web Development" },
  { name: "UI/UX Designs", route: "service-details?name=Product Design" },
  { name: "DevOps", route: "service-details?name=DevOps" },
  { name: "Q/A Services", route: "service-details?name=Quality Assurance" },
];

export const resources = [
  { name: "Blog", route: "blogs" },
  { name: "Case Studies", route: "portfolio" },
  { name: "Careers", route: "careers" },
];
export const contact_us = [
  { name: "Pakistan:", route: "home" },
  { name: "46 Block B PCSIR 2", route: "home" },
  { name: "Johar Town Lahore", route: "home" },
  { name: "+92 42 35315822", route: "home" },
  { name: "", route: "home" },
  { name: "UK:", route: "home" },
  { name: "9 College Drive", route: "home" },
  { name: "Dunstable LU5 4 NB", route: "home" },
  { name: "+44 7468434033", route: "home" },
];

export const whyCardsData = [
  {
    image: "images/img_frame135.svg",
    heading: "Virtuous Partnerships",
    details:
      "Customer prosperity is our priority ad we go extra-miles to tackle issues our customers are confronting with their digital innovation execution",
  },
  {
    image: "images/img_frame61.svg",
    heading: "Exceeding Expectations",
    details:
      "We believe that every task or situation an opportunity to go above and beyond what is expected by our clients and stakeholders",
  },
  {
    image: "images/img_frame244.svg",
    heading: "Assured Quality",
    details:
      "We never settle for less, and with integrated quality assurance processes, we ensure that your products are market-ready before launch",
  },
  {
    image: "images/img_crop.svg",
    heading: "Customization",
    details:
      "We develop software services to meet the unique needs of each client, with our highly flexible process that adapts to changing requirements",
  },
];

export const TeamCardData = [
  {
    image: "images/teamyellow.png",
    heading: "Select the Right Expert",
    shadow: "#FFCC01",
    details:
      "Handpick from our talent pool, ensuring that you have the right expertise tailored to your project's needs.",
  },
  {
    image: "images/teamgreen.png",
    heading: "Kick Off with an Introductory Interview",
    details:
      "Engage directly with potential team members. This step ensures a perfect fit in terms of skills and project requirements.",
    shadow: "#56DE7D",
  },
  {
    image: "images/teamblue.png",
    heading: "Onboard Developers Promptly",
    details:
      "We value your time. With our streamlined process, you can have developers on your project in no time.",
    shadow: "#00A0CC",
  },
];

export const CoreValuesData = [
  {
    image: "images/exceed.png",
    heading: "Exceed client expectations",
    details:
      "We build trust by ensuring that the clients’ needs and requirements are met and exceeded in every project",
  },
  {
    image: "images/foster.png",
    heading: " Foster a collaborative environment",
    details:
      "Our team works closely with clients and colleagues  to foster a culture of synergy and open communication",
  },
  {
    image: "images/maintain.png",
    heading: "Maintain continuous accountability",
    details:
      "Our developers take ownership of individual and team responsibilities and deliver timely on commitments",
  },
];
export const job_openings = [
  {
    position: "IOS Developer",
    location: "United States",
    time: "Full Time",
  },
  {
    position: ".NET Cloud Developer",
    location: "United States",
    time: "Full Time",
  },
  {
    position: "Front-end Developer",
    location: "Pakistan",
    time: "Full Time",
  },
];

export const domain_experience = {
  icon: "images/DomainExperience.webp",
  heading: "Domain Experience",
  detail:
    "We provide you the best IT professionals with sufficient domain experience to fill your skills gap and improve your product quality",
};
export const product_growth = {
  icon: "images/ProductGrowth.webp",
  heading: "Product Growth",
  detail:
    "Our goal is to make your product growth skyrocket. We’re all about hitting your KPIs. That means no fluff, no excuses, ever",
};
export const faster_launch = {
  icon: "images/FasterLaunchTimes.webp",
  heading: "Faster Launch Times",
  detail:
    "Through customer centric design and best-in-class software development, our teams help you to bring your ideas to market, faster",
};
export const process_visibility = {
  icon: "images/ProcessVisibilty.webp",
  heading: "Process Visibility",
  detail:
    "We combine accessibility, flexibility and integration to derive process visibility dimensions from information quality",
};
export const website_development = {
  heading: "Website Development",
  details:
    "Our web applications are profoundly versatile, empowering the finest client involvement in any case of the measure of their screen",
  icons_data: [
    {
      icon: "images/java23.svg",
      heading: "Java",
    },
    {
      icon: " images/node23.png",
      heading: "Node Js",
    },
    {
      icon: "images/angular23.svg",
      heading: "Angular",
    },
    {
      icon: "images/js23.svg",
      heading: "JavaScript",
    },
    {
      icon: "images/jquery23.png",
      heading: "Jqeury",
    },
    {
      icon: "images/bootstrap23.svg",
      heading: "Bootstrap",
    },
    {
      icon: "images/firbase23.png",
      heading: "Firebase",
    },
    {
      icon: "images/rails23.svg",
      heading: "ROR",
    },
    {
      icon: "images/mongo23.svg",
      heading: "MongoDB",
    },
    {
      icon: "images/sql23.png",
      heading: "SQlite",
    },
  ],
};
export const mobile_development = {
  heading: "Mobile Development",
  details:
    "We use best industry technologies and practices that assist you in making a product that delights your clients",
  icons_data: [
    {
      icon: "images/andriod23.png",
      heading: "Android",
    },
    {
      icon: " images/apple23.png",
      heading: "IOS",
    },
    {
      icon: "images/flutter23.png",
      heading: "Flutter",
    },
    {
      icon: "images/react23.png",
      heading: "React-Native",
    },
    {
      icon: "images/node23.png",
      heading: "Node Js",
    },
  ],
};
export const product_design = {
  heading: "Product Design",
  details:
    "We implement UI/UX design and advancement, to make your solutions have an eye-filling look and behaviour. This will assist you to extend future item appropriation",
  icons_data: [
    {
      icon: "images/Figma.svg",
      heading: "Figma",
    },
    {
      icon: " images/protoio23.svg",
      heading: "Proto.io",
    },
    {
      icon: "images/adobe23.png",
      heading: "Adobe Xd",
    },
    {
      icon: "images/Maze.svg",
      heading: "Maze",
    },
    {
      icon: "images/miro23.png",
      heading: "Miro",
    },
  ],
};
export const dev_ops = {
  heading: "DevOps",
  details:
    "Our experienced DevOps consultants improve your services using latest tech",
  icons_data: [
    {
      icon: "images/img_9073807azureicon.svg",
      heading: "Azure",
    },
    {
      icon: " images/img_8546827awsicon.svg",
      heading: "AWS",
    },
    {
      icon: "images/docker23.svg",
      heading: "Docker",
    },
  ],
};

export const iconsArray = [
  {
    image: "images/img_healthcaresvgrepocom.svg",
    title: "Healthcare",
    color: "#56DE7D",
  },
  {
    image: "images/img_home.svg",
    title: "Education",
    color: "#DE568F",
  },
  {
    image: "images/img_homesvgrepocom.svg",
    title: "Real Estate",
    color: "#DEC056",
  },
  {
    image: "images/img_schoolbussvgrepocom.svg",
    title: "Travel",
    color: "#DE568F",
  },
  {
    image: "images/img_dollar1svgrepocom.svg",
    title: "Finance",
    color: "#9256DE",
  },
  {
    image: "images/img_weightliftingsvgrepocom.svg",
    title: "Wellness & Fitness",
    color: "#56DE7D",
  },
  {
    image: "images/img_schoolbussvgrepocom.svg",
    title: "Transportation",
    color: "#DEC056",
  },
  {
    image: "images/financeCart.png",
    title: "Finance",
    color: "#9256DE",
  },
];

export const areas_of_expertise = [
  {
    id: 1,
    title: "Patient Management",
    image: "images/material-symbols_patient-list-rounded.png",
    subHeading: "Find a doctor, Hospital, or Specialty.",
    color: "#5AC7DE",
  },
  {
    id: 2,
    title: "Tele Health Consultation",
    image: "images/Group 7131.png",
    subHeading: "Offering you accessible, reliable, convenient healthcare.",
    color: "#56DE7D",
  },
  {
    id: 3,
    title: "Hospital Management",
    image: "images/Group 7130.png",
    subHeading:
      "View profile, attendance, leave, payroll, Reporting & Analytics.",
    color: "#FFC700",
  },
  {
    id: 4,
    title: "Fleet Management System",
    image: "images/Vector (1).png",
    subHeading:
      "Offering real time monitoring, Alerts for vehicle maintenance, Cost analysis & Optimization.",
    color: "#9256DE",
  },
];

export const gaming_expertise = [
  {
    id: 1,
    title: "ART WORK",
    image: "images/material-symbols-light_work-update.png",
    subHeading:
      "Craft intricate 2D/3D designs, including character modelling, environmental art, and animations for games and applications.",
    color: "#5AC7DE",
  },
  {
    id: 2,
    title: "GAME OPTIMIZATION",
    image: "images/Optimization.png",
    subHeading:
      "Improving performance, graphics, and resource usage to optimize games for different devices and platforms.",
    color: "#56DE7D",
  },
  {
    id: 3,
    title: "GAMES DEVELOPMENT",
    image: "images/material-symbols-light_developer-mode-tv.png",
    subHeading:
      "Development services for 2D/3D games, including offline/multiplayer experiences, with captivating graphics for cross-platform deployment.",
    color: "#FFC700",
  },
  {
    id: 4,
    title: "2D/3D ANIMATION",
    image: "images/mdi_animation-play.png",
    subHeading:
      "Bring your ideas to life with professional animation services, enhancing the impact and appeal of your projects",
    color: "#9256DE",
  },
];

export const our_mission = [
  {
    heading: "Our Mission",
    details:
      "Our mission is to enhance business growth of our customers with creative design, development and to deliver market defining high quality solutions that create value and reliable competitive advantage to customers around the globe.",
    detail2:
      "Our competencies are always kept up with any technology change, so we offer you latest trends in the industry.",
    image: "images/img_image46.png",
  },
  {
    heading: "Our Vision",
    details:
      "Our vision is to create innovative software solutions that solve complex problems and improve the lives of people. The company strives to be a leader in the industry by providing high-quality software products that are reliable, secure, and user-friendly.",
    image: "images/Picture.jpg",
  },
];

export const project_list = [
  {
    heading: "Healthcare",
    name: "01",
  },
  {
    heading: "eCommerce",
    name: "02",
  },
  {
    heading: "Retail",
    name: "03",
  },
  // {
  //   heading: "Banking",
  //   name: "04",
  // },
];
export const project_list_data = [
  {
    heading: "Healthcare",
    detail:
      "Healthcare software has become a need for today's health organizations. Leverage our custom healthcare software development services to improve patient outcomes.",
    image: "images/almana23.svg",
  },
  {
    heading: "eCommerce",
    detail:
      "We create eCommerce apps that are powerful and flexible. Our UI/UX design team makes sure your app looks great so it attracts more customers. Adapt quickly to the ever-evolving market with our custom solutions.",
    image: "images/keyki23.svg",
  },
  {
    heading: "Retail",
    detail:
      "In today's digital age, the retail industry has undergone a significant transformation, driven by advancements in information technology (IT). This transformation has not only changed the way retailers operate but has also revolutionized the shopping experience for consumers.",
    image: "images/sendoso23.svg",
  },
  {
    heading: "Banking",
    detail:
      "We help financial firms eliminate paper from their workflows and automate tedious tasks with our custom-built software. Our team also creates algorithmic apps that forecast market movements.",
    image: "images/Gigmi 5.webp",
  },
];

export const all_projects = [
  {
    name: "Almana General Hospital",
    heading: "Healthcare",
    area: "Web development",
    background: "#56C6DE",
    detail:
      "Almana General Hospital has been dedicated to bringing standard-setting healthcare.",
    image: "images/almana.jpg",
    date: "15 March 2021",
    clientname: "Shater Abdurahman",
  },
  {
    name: "KEYKI",
    heading: "eCommerce",
    area: "Mobile development",
    background: "#FF5D52",
    detail:
      "Keyki is an Online Shopping Platform introduced in Pakistan that allows you to Shop Millions of Products with Best Online Shopping Discounts.",
    image: "images/keyki.jpg",
    date: "15 july 2021",
    clientname: "Ahmed Shahzad",
  },
  {
    name: "Sendoso",
    heading: "Retail",
    area: "Web development",
    background: "#9256DE",
    detail:
      "Sendoso helps companies stand out by giving them new ways to engage with customers throughout the buyer's journey.",
    image: "images/sendoso.jpg",
    date: "19 March 2016",
    clientname: "chris",
  },

  {
    name: "eServices",
    heading: "Healthcare",
    area: "Mobile development",
    background: "#FF5D52",
    detail:
      "AGH - eServices mobile application allows users to view and apply for leaves (short, annual, rejoining).",
    image: "images/almana-hospital-3.jpg",
    date: "15 August 2021",
    clientname: "Shater Abdurahman",
  },
  {
    name: "Gigmi",
    heading: "eCommerce",
    area: "Mobile development",
    background: "#f47e09",
    detail:
      "Gigmi was for making a bridge between a Contractor and a Tradesman.",
    image: "images/gigmi.jpg",
    date: "15 Feb 2022",
    clientname: "Altholy Harvey",
  },
];

export const made_off = [
  {
    type: "Knowledge",
    image: "images/Usama.jpg",
    name: "Usama Riaz",
    designation: "Software Engineer",
    quote:
      "I feel the people I'm working with here have my best interest at heart. Development has been my passion for some time now, but what's passion without knowledge? I'm learning something new here everyday. At my own pace, no pressure",
  },
  {
    type: "friendship",
    image: "images/Hassem.jpg",
    name: "Hassem Mehboob",
    designation: " Software Engineer",
    quote:
      "Friendship in an IT company is like the perfect code—strong connections, seamless collaboration, and endless debugging support, crafting a masterpiece of innovation and success.",
  },
  {
    type: "inclusivity",
    image: "images/Shahrukh.jpg",
    name: "Shahrukh Azhar",
    designation: "Product Designer",
    quote:
      "Inclusivity in an IT company is the code that unlocks infinite possibilities, embracing diversity as the key to innovation, collaboration, and a brighter future for all",
  },
  {
    type: "smiles",
    image: "images/Moaz.jpg",
    name: "Mouaz Akhtar ",
    designation: "SDR",
    quote:
      "Smiles are the universal language of the IT realm, bridging connections between teams. A single smile has the power to brighten the digital landscape and ignite a contagious enthusiasm that fuels creativity and success",
  },
  {
    type: "football",
    image: "images/omair.png",
    name: "Omair Shahid Nagi ",
    designation: "HR & Operations",
    quote:
      "Football and IT share a common spirit—teamwork, strategy, and relentless pursuit of goals. In the realm of technology, we assemble our digital squad, execute precision plays, and celebrate victories, knowing that the power of collaboration drives us to score remarkable achievements.",
  },
];

export const blogs_list_data = [
  {
    heading: "Microservices For Frontend Architecture",
    image: "images/P1-1200x800.webp",
    date: "25 May 2022",
    color: "#FF5D52",
    id: 1,
  },
  {
    heading: "How to Inject HTML into React Using IFrame",
    image: "images/P4-1200x800.webp",
    date: "25 June 2020",
    color: "#9256DE",
    id: 2,
  },
  {
    heading: "How to Convert Redux to Use React Hooks",
    image: "images/P5-1200x800.webp",
    date: "1 March 2020",
    color: "#FFC700",
    id: 3,
  },
];

export const project_types = [
  {
    name: "All",
  },
  {
    name: "Healthcare",
  },
  {
    name: "Retail",
  },
  {
    name: "eCommerce",
  },
];

export const product_details = [
  {
    carosal: {
      name: "Sendoso",
      detail:
        "Sendoso helps companies stand out by giving them new ways to engage with customers throughout the buyers journey.",
      image: "images/sendoso 2.webp",
    },
    client: [
      {
        heading: "Clinet Name",
        name: "Sendoso",
      },
      {
        heading: "Industry",
        name: "Retail",
      },
      {
        heading: "Time Spent",
        name: "3 months",
      },
      {
        heading: "Tags",
        name: ".Net, UI/UX ",
        border: true,
      },
    ],
    challenge_details:
      "At the start of Sendoso, they faced three significant obstacles: Wearhouse issues, Team Scaling and Tech Expertise. We offer the best solutions!",
    challenge_image: "images/sendoso.jpg",
    solution_steps: [
      {
        heading: "step1",
        details:
          "Pikes Soft provided the complete technical team including software developers, QA engineers and UI/UX designers to Sendoso at the time of starting the project.",
        image: "images/sendoso 3.jpg",
        background_image: "images/Polygon 6.png",
      },
      {
        heading: "step2",
        details:
          "Initially there were only 6 developers.After 3 years, we helped them to scale the team to 125+ software engineers and make the company entirely remote.And the same partner acquires one of our development offices.",
        image: "images/Sendoso 1.jpg",
        background_image: "images/Polygon 7.png",
      },
      {
        heading: "step3",
        details:
          "We provided complete after-sales technical support to Sendoso for almost 2 years before it became a separate entity.",
        image: "images/Sendoso 4.jpg",
        background_image: "images/Polygon 6.png",
      },
    ],
  },
  {
    carosal: {
      name: "KEYKI",
      detail:
        "B2c & B2Nb video-based e-commerce marketplace. We enable new online businesses in a single day by providing an opportunity to buy operational single product online stores instantly",
      image: "images/Keyki 1.jpg",
    },
    client: [
      {
        heading: "Client Name",
        name: "Ahmad Shahzad",
      },
      {
        heading: "Indusrty ",
        name: "E-commerce",
      },
      {
        heading: "Time Spent",
        name: "2 Years",
      },
      {
        heading: "Tags",
        name: "React Native, UI/UX ",
        border: true,
      },
    ],
    challenge_details:
      "We had to merge the eCommerce market with the TikTok app and give them both experiences in one application. Had to design wireframes such that we can provide the right experience to the audience of both ecommerce as well as TikTok .Had to divide the Products in specific categories so user can easily filter out the specific product",
    challenge_image: "images/Keyki 2.jpg",
    solution_steps: [
      {
        heading: "Step 1",
        details:
          "There are many ecommerce projects available online but we had to make Keyki different from others by introducing a video based user experience in ecommerce market, previously user could only view the images of available products but in Keyki we introduced the video based dynamic user experience in mobile app.",
        image: "images/Keyki 4.jpg",
        background_image: "images/Polygon 6.png",
      },
      {
        heading: "Step 2",
        details:
          "Categorized products into Specific Categories so it would also help out in filtering the products.",
        image: "images/Keyki 3.jpg",
        background_image: "images/Polygon 7.png",
      },
      {
        heading: "Step 3",
        details:
          "Introduced Segmented switcher to switch from video to pictures if user wants to view pictures only.",
        image: "images/Keyki 5.jpg",
        background_image: "images/Polygon 6.png",
      },
    ],
  },
  {
    carosal: {
      name: "Gigmi",
      detail:
        "Gigmi was for making a bridge between a Contractor and a Tradesman. It was previously Designed by the U.S Designer but the client wasnt quite happy with the designs and user flow. So as a good gesture we propose a new design with improved UX and a new interface look",
      image: "images/Gigmi 4.jpg",
    },
    client: [
      {
        heading: "Client Name",
        name: "Anthony Harvey",
      },
      {
        heading: "Indusrty ",
        name: "E-commerce",
      },
      {
        heading: "Time Spent",
        name: "2+ Years",
      },
      {
        heading: "Tags",
        name: "React Native, UI/UX ",
        border: true,
      },
    ],
    challenge_image: "images/Gigmi 1.jpg",
    challenge_details:
      "We had to define a design system and create a User journey but as the first release was launched and we had to stick with the same logo and color scheme as it was requested by the client that original identity of the app dont lost in revamping the current design. So we had to stick with same logo and color scheme with New Interface look. We had to keep all the risks involve and the target audience as well. challengle_image",
    solution_steps: [
      {
        heading: "Step 1",
        details:
          "User-friendly Interface: We will design an intuitive and user-friendly interface that makes it easy for Contractors and Tradesmen to create profiles, search for relevant projects or services, and communicate effectively.",
        image: "images/Gigmi 2.jpg",
        background_image: "images/Polygon 6.png",
      },
      {
        heading: "Step 2",
        details:
          "Project Management: Our platform will offer robust project management tools, allowing users to create, manage, and track projects from start to finish. This includes features such as task assignment, progress tracking, and real-time collaboration.",
        image: "images/Gigmi 3.jpg",
        background_image: "images/Polygon 7.png",
      },
      {
        heading: "Step 3",
        details:
          "Secure Payment System: We will integrate a secure payment system that enables hassle-free transactions between Contractors and Tradesmen. This will include options for milestone-based payments, escrow services, and automated invoicing for improved financial management.",
        image: "images/Gigmi 5.jpg",
        background_image: "images/Polygon 6.png",
      },
    ],
  },
  {
    carosal: {
      name: "eServices",
      detail:
        "AGH - eServices mobile application allows users to view and apply for leaves (short, annual, rejoining), view their profile, attendance, entitlement, payroll, apply for online tickets, approve leaves and online tickets, and track their leaves.",
      image: "images/Almana 4.jpg",
    },
    client: [
      {
        heading: "Client Name",
        name: "SHATER ABDURAHMAN",
      },
      {
        heading: "Indusrty ",
        name: "Healthcare",
      },
      {
        heading: "Time Spent",
        name: "3+ Years",
      },
      {
        heading: "Tags",
        name: "React, .Net, Figma ",
        border: true,
      },
    ],
    challenge_details:
      "Need to make a user-friendly design as most of the users were new to the automation system and not familiar to apply for online leave. Tracking of online application for the user to see the current status of their application, whether it is approved or rejected or in the way of the process.",
    challenge_image: "images/Almana 1.jpg",
    solution_steps: [
      {
        heading: "Step 1",
        details:
          "This application is basically used for almana employees as they have over 6500+ employees and more than 800+ doctors in around 8 medical facilities so it was very difficult for the organization to keep track all of them manually.",
        image: "images/Almana 3.jpg",
        background_image: "images/Polygon 6.png",
      },
      {
        heading: "Step 2",
        details:
          "The main menu was designed simple so everyone can use the app accordingly. All Main options were placed on the home dashboard where everyone can access it easily.",
        image: "images/Almana 5.jpg",
        background_image: "images/Polygon 7.png",
      },
      {
        heading: "Step 3",
        details:
          "All Employees can check their previous leaves and annual leaves left, also their check-in time at the hospital",
        image: "images/Almana 2.jpg",
        background_image: "images/Polygon 6.png",
      },
    ],
  },

  {
    carosal: {
      name: "Almana General Hospital",
      detail:
        "Since 1945, Almana General Hospital has been dedicated to bringing standard-setting healthcare. The first private general hospital in the Eastern Provence and, one of the oldest and largest medical company in Gulf Region, AGH continue its tradition of excellence today.",
      image: "images/Almana.png",
    },
    client: [
      {
        heading: "Client Name",
        name: "SHATER ABDURAHMAN",
      },
      {
        heading: "Indusrty ",
        name: "Healthcare",
      },
      {
        heading: "Time Spent",
        name: "3+ Years",
      },
      {
        heading: "Tags",
        name: "React, NodeJS Figma ",
        border: true,
      },
    ],
    challenge_details:
      "The Challenging part for us was to change the UI and client stream of currently made application with a similar functionality. We had to map out all the functionalities which were in the previous app should also be accommodated in the New design and Components. We had to improve the user experience by keeping the target audience in mind .",
    challenge_image: "images/hospital5.jpg",
    solution_steps: [
      {
        heading: "Step 1",
        details:
          "In Previous Design, there was a major issue of User Journey and it was very difficult for the user to explore all the options available. There was no Search Option Available if the user want to explore or visit any specific doctor, he has to click on the option of book an appointment to reach the expected result",
        image: "images/Home Screen.jpg",
        background_image: "images/Polygon 6.png",
      },
      {
        heading: "Step 2",
        details:
          "We revamp the design according to Modern UI and minimalized the screens as much as we could",
        image: "images/Digilab.jpg",
        background_image: "images/Polygon 6.png",
      },
      {
        heading: "Step 3",
        details:
          "Keeping in mind the challenges we had we improve the Search navigation so the patient can explore maximum results. Designed bottom navigation for the user to allow quick access to the profile, appointments & Inbox",
        image: "images/hospital4.jpg",
        background_image: "images/Polygon 6.png",
      },
    ],
  },
];
export const service_details = [
  {
    carosal: {
      type: "Healthcare",
      name: "Mobile Development",
      detail:
        "We provide 360 solutions ranging from communication, social networking, and entertainment mobile apps for Android and iOS",
      image: "images/Almana 3.jpg",
    },
    product_heading:
      "Mobile development services to build innovative experiences",
    product_details:
      "Undertake a modernized technique toward cellular application development with Pikessoft – the main Mobile development agency. We deliver high-overall performance, function-packed cellular reviews for cell gadgets, wearables, IoT, or augmented truth gadgets.",
    project_details: {
      Heading: "Mobile Development Services",
      details:
        "End-to-end conceptualization, design, and support for your mobile app",
      list: [
        {
          image: "images/img_androidsvgrepocom.svg",
          title: "Andriod",
          subHeading:
            "Combine custom tech solutions and business objectives to reach the world’s largest app market ",
        },
        {
          image: "images/img_applesvgrepocom.svg",
          title: "IOS",
          subHeading:
            "We create well-designed agile development across all iOS platforms",
        },
        {
          image: "images/img_location_black_900.svg",
          title: "Flutter",
          subHeading:
            "Develop for multiple devices to achieve higher market penetration, with React Native or Flutter",
        },
      ],
    },
    experties: {
      image1: "images/Android.svg",
      image2: "images/IOS.svg",
      image3: "images/React.svg",
      image4: "images/Flutter.svg",
    },
  },
  {
    carosal: {
      type: "Retail",
      name: "Web Development",
      detail:
        "Having been in the business for over a decade, we love to convert your ideas into reality and fulfilling your dreams by offering a scalable solution.",
      image: "images/Almana 2.jpg",
    },
    product_heading:
      "Web application company offering a fully-optimized solution",
    product_details:
      "Pikessoft Website Application Company specializes in custom website design and development. For businesses, our website designers and developers create enterprise-level websites and web portals.",
    project_details: {
      Heading: "Web Development Services",
      details:
        "Client-focused and customer-centric website solutions that deliver tangible business results",
      list: [
        {
          image: "images/img_nodejssvgrepocom.svg",
          title: "Node.js",
          subHeading:
            "Build lightweight real-time applications based on a microservice architecture",
        },
        {
          image: "images/img_clock_black_900.svg",
          title: "Ruby on Rails",
          subHeading:
            "Create new functionalities for your existing system, or tackle technical issues.",
        },
        {
          image: "images/img_python127svgrepocom.svg",
          title: "Python",
          subHeading:
            "Create powerful web apps using less code and integrate their scripts with other languages.",
        },
      ],
    },
    experties: {
      image1: "images/Java.svg",
      image2: "images/img_nodejsicon1.svg",
      image3: "images/img_image69.png",
      image4: "images/Angular.svg",
    },
  },
  {
    carosal: {
      type: "eCommerce",
      name: "Product Design",
      detail:
        "We apply collaborative, human-centered design thinking to even the toughest of business challenges. With a relentless commitment to innovation and world-class craftsmanship, we deliver the most beautiful, meaningful and impactful experiences for your customers",
      image: "images/Keyki 1.jpg",
    },
    product_heading:
      "Pikes Soft Pivots On A Solid Foundation, One That Is Built On Your Vision & Our Expertise.",
    product_details:
      "Pikes Soft has a proven track record of delivering impactful customer experiences, unlocking the power of technology within organizations, and stimulating growth with brave new initiatives",
    project_details: {
      Heading: "Product Design Services ",
      details:
        "Ideation and creation of software products across every stage of the life cycle",
      list: [
        {
          image: "images/img_svgfreepiksto.svg",
          title: "User Experience",
          subHeading:
            "We create products that provide meaningful and relevant experiences to users.",
        },
        {
          image: "images/img_computer_gray_900_03.svg",
          title: "User Interface",
          subHeading:
            "Create new functionalities for your existing system, or tackle technical issues.",
        },
        {
          image: "images/img_branding.svg",
          title: "Branding",
          subHeading:
            "We create a digital presence uniquely marketed to optimize reach and sales.",
        },
      ],
    },
    experties: {
      image1: "images/Maze.svg",
      image2: "images/Miro.svg",
      image3: "images/Adobe Xd.svg",
      image4: "images/Figma.svg",
    },
  },
  {
    carosal: {
      type: "eCommerce",
      name: "DevOps",
      detail:
        "Our engineers examine key pain points, suggest gear and implement techniques to automate deployment cycle and improve infrastructure.",
      image: "images/img_frame7469.png",
    },
    product_heading: "Enhance Your IT Team’s Performance with DevOps",
    product_details:
      "Devops answers automate away inefficiencies and enhance the high-quality and protection of software. Pikessoft devops consulting services assist corporations optimize their it group’s performance. ",
    project_details: {
      Heading: "DevOps Services",
      details:
        "Iterative and incremental development promoting increased agility and enhanced quality.",
      list: [
        {
          image: "images/img_3669450assessmenticicon.svg",
          title: "Assessment",
          subHeading:
            "Evaluate enhanced capabilities and provide a visualisation of your transformation journey.",
        },
        {
          image: "images/img_settings.svg",
          title: "Automation",
          subHeading:
            "Reduce human assistance to processes that facilitate feedback loops between various teams.",
        },
        {
          image: "images/img_subaccountssvgrepocom.svg",
          title: "Management",
          subHeading:
            "Maintain and execute organizational procedures for configuration and release management.",
        },
      ],
    },
    experties: {
      image1: "images/img_image63.png",
      image2: "images/img_image64.png",
      image3: "images/img_image65.png",
      image4: "images/img_image66.png",
    },
  },
  {
    carosal: {
      type: "Healthcare",
      name: "Quality Assurance",
      detail:
        "Our enthusiastic squad of well equipped personals helps you build reliable products by offering Performance and Security Testing, Mobile and Manual Testing",
      image: "images/almana-hospital-3.jpg",
    },
    product_heading: "Let’s Make Your Product Reliable",
    product_details:
      "We offer stop-to-give-up checking out services at each step of the software program development lifecycle to make sure all included portions of answers are performing as designed from the start to complete.",
    project_details: {
      Heading: "Quality Assurance Services",
      details:
        "Safeguard your product’s quality by managing risks and preventing flaws and issues.",
      list: [
        {
          image: "images/img_performanceup.svg",
          title: "Performance Testing",
          subHeading:
            "Evaluate system performance with responsiveness and stability under a particular workload.",
        },
        {
          image: "images/img_securityverifi.svg",
          title: "Security Testing",
          subHeading:
            "Reveal flaws in the security mechanisms of an information system that protect data.",
        },
        {
          image: "images/img_upload.svg",
          title: "Test Automation",
          subHeading:
            "Test software and other tech products to ensure it meets strict requirements.",
        },
      ],
    },
    experties: {
      image1: "images/Java.svg",
      image2: "images/img_nodejsicon1.svg",
      image3: "images/img_image69.png",
      image4: "images/Angular.svg",
    },
  },
];

export const service_list = [
  {
    subHeading:
      "Build feature-packed and well-designed custom mobile applications in iOS or Android ",
    heading: "Mobile Development Services",
    detail:
      "End-to-end conceptualization, design, and support for your mobile app",
    navigateTo: "Mobile Development",
    vector: "images/vector01.png",
    image: "images/mobile01.png",
    techArray: [
      {
        image: "images/img_androidsvgrepocom.svg",
        title: "Andriod",
        subHeading:
          "Combine custom tech solutions and business objectives to reach the world’s largest app market ",
      },
      {
        image: "images/img_applesvgrepocom.svg",
        title: "IOS",
        subHeading:
          "We create well-designed agile development across all iOS platforms",
      },
      {
        image: "images/img_location_black_900.svg",
        title: "Flutter",
        subHeading:
          "Develop for multiple devices to achieve higher market penetration, with React Native or Flutter",
      },
    ],
  },
  {
    subHeading:
      "We provide high-tech web app development services that highly-responsive & outstandingly expeditious designed & developed by keeping user-approached excellence at priority.",
    heading: "Web Development Services",
    detail:
      "Client-focused and customer-centric website solutions that deliver tangible business results",
    navigateTo: "Web Development",
    vector: "images/vector02.png",
    image: "images/web02.png",
    techArray: [
      {
        image: "images/img_nodejssvgrepocom.svg",
        title: "Node.js",
        subHeading:
          "Build lightweight real-time applications based on a microservice architecture",
      },
      {
        image: "images/img_clock_black_900.svg",
        title: "Ruby on Rails",
        subHeading:
          "Create new functionalities for your existing system, or tackle technical issues.",
      },
      {
        image: "images/img_python127svgrepocom.svg",
        title: "Python",
        subHeading:
          "Create powerful web apps using less code and integrate their scripts with other languages.",
      },
    ],
  },
  {
    subHeading:
      "We create beautiful and engaging interfaces for your products so you can achieve your business goals and impress your customers from the first moment.",
    heading: "Product Design Services",
    detail:
      "Ideation and creation of software products across every stage of the life cycle",
    navigateTo: "Product Design",
    vector: "images/vector03.png",
    image: "images/design03.png",
    techArray: [
      {
        image: "images/img_svgfreepiksto.svg",
        title: "User Experience",
        subHeading:
          "We create products that provide meaningful and relevant experiences to users.",
      },
      {
        image: "images/img_computer_gray_900_03.svg",
        title: "User Interface",
        subHeading:
          "Create new functionalities for your existing system, or tackle technical issues.",
      },
      {
        image: "images/img_branding.svg",
        title: "Branding",
        subHeading:
          "We create a digital presence uniquely marketed to optimize reach and sales.",
      },
    ],
  },
  {
    subHeading:
      "We help you automate, scale, and modernise product engineering by improving and shortening the systems development lifecycle.",
    heading: "DevOps Services",
    detail:
      "Iterative and incremental development promoting increased agility and enhanced quality.",
    navigateTo: "DevOps",
    vector: "images/vector04.png",
    image: "images/dev04.png",
    techArray: [
      {
        image: "images/img_3669450assessmenticicon.svg",
        title: "Assessment",
        subHeading:
          "Evaluate enhanced capabilities and provide a visualisation of your transformation journey.",
      },
      {
        image: "images/img_settings.svg",
        title: "Automation",
        subHeading:
          "Reduce human assistance to processes that facilitate feedback loops between various teams.",
      },
      {
        image: "images/img_subaccountssvgrepocom.svg",
        title: "Management",
        subHeading:
          "Maintain and execute organizational procedures for configuration and release management.",
      },
    ],
  },
  {
    subHeading:
      "We ensure your product’s security and success by preventing issues and safeguarding overall quality, so that your customers can have an enjoyable and reliable experience.",
    heading: "Quality Assurance Services",
    detail:
      "Safeguard your product’s quality by managing risks and preventing flaws and issues.",
    navigateTo: "Quality Assurance",
    vector: "images/vector05.png",
    image: "images/quality05.png",
    techArray: [
      {
        image: "images/img_performanceup.svg",
        title: "Performance Testing",
        subHeading:
          "Evaluate system performance with responsiveness and stability under a particular workload.",
      },
      {
        image: "images/img_securityverifi.svg",
        title: "Security Testing",
        subHeading:
          "Reveal flaws in the security mechanisms of an information system that protect data.",
      },
      {
        image: "images/img_upload.svg",
        title: "Test Automation",
        subHeading:
          "Test software and other tech products to ensure it meets strict requirements.",
      },
    ],
  },
];

export const Developers = [
  {
    name: "Fawad Naeem",
    image: "images/img_image50.png",
    designation: "CTO, Tech Visionary & Strategist",
    details:
      " A tech maven renowned for merging visionary leadership, always on the hunt for the next technological horizon",
    color: "#FFCC01",
    link: "https://calendly.com/shahzaib-ede/30min",
  },
  {
    name: "Sohail Mohabbat Ali",
    image: "images/sohail.jpg",
    designation: "Senior Software Architect",
    color: "#E3285F",
    link: "https://calendly.com/shahzaib-ede/30min",
    details:
      " Combines deep technical knowledge with a passion for creating efficient software. A valuable asset for any team.",
  },
  {
    name: "Shahzaib Saleem",
    image: "images/shahzab.jpg",
    designation: "Data Engineer & E-Commerce Expert",
    details:
      "A tech virtuoso in software engineering, translating innovative ideas into tangible business results.",
    color: "#00B7E9",
    link: "https://calendly.com/shahzaib-ede/30min",
  },
];
