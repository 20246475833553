/** @format */

import Button from "../../components/Button";
import { Img } from "../../components/Img";
import { Text } from "../../components/Text";
import { header_options } from "../../constants/StaticData";
import React, { useState, useEffect } from "react";
import "./styles.css";
import {useLocation} from "react-router-dom"

const Header = (props) => {
  const location=useLocation();
  const [isOpen, setIsOpen] = useState(true);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth > 899) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const navigateToPage = (path) => {
    window.location.href = path;
  };

  return (
    <>
      <header className='fixed-header'>
        <div className=' items-center justify-center w-full '>
          <div
            className='header-main items-center text-white pt-[20px] pb-[20px]'
            style={{ borderBottomWidth: 1, justifyContent: "space-between" }}>
            <div className='flex'>
              <div
                onClick={() => toggleMenu()}
                className=' items-center flex mobile'>
                   <Img
                  src='images/Hamburger.png'
                  className='h-[35px] w-[35px] object-contain mb-[7px]'
                  alt='group'
                  height='52'
                />
              </div>
              <div
                className='justify-center justify-canter ml-[80px]'
                style={{ cursor: "pointer" }}
                onClick={() => navigateToPage("/home")}>
                <Img
                  src="images/pikessoftlogowhite.svg"
                  className='h-[52px] mb-[7px]'
                  alt='group'
                  height='52'
                  width='129'
                />
              </div>
            </div>
            {isOpen && (
              <div className='routes pl-[0px]  sm:gap-5 items-start justify-center '>
                {header_options.map((data,index) => (
                  <div key={index} className="mt-[7px]" onClick={() => navigateToPage("/" + data.route)}>
                    <Text
                      className= {`${location.pathname === ('/'+data.route) ? 'hover':"" } pointer font-redhatmono  sm:ml-[0] ml-[22px] text-white text-left w-auto font-bold`}
                      variant='body37'>
                      {data.name}
                    </Text>
                  </div>
                ))}
              </div>
            )}
            <div
              className='web mr-[40px]'
              onClick={() => (window.location.href ="https://calendly.com/shahzaib-ede/30min")}>
              <Button label='Book a Meeting' Icon />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
