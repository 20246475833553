// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-main{
    background: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 899px) {
    .header-main{
        padding-left: 20px;
    }
}

.hover {
    border-bottom-width: 2px;
    border-bottom-color:white;
    padding-bottom: 5px;
    cursor: pointer;


  }
  .pointer:hover{
    cursor: pointer;
  }

@media screen and (min-width: 900px) {
    .header-main{
        display: flex;
        flex-direction: row;
    }   
    .routes{
        display: flex;
        flex-direction: row;

    }

}

.fixed-header{
    position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/styles.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI,wBAAwB;IACxB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;;;EAGjB;EACA;IACE,eAAe;EACjB;;AAEF;IACI;QACI,aAAa;QACb,mBAAmB;IACvB;IACA;QACI,aAAa;QACb,mBAAmB;;IAEvB;;AAEJ;;AAEA;IACI,eAAe;EACjB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,aAAa;AACf","sourcesContent":[".header-main{\n    background: rgba(0, 0, 0, 0.8);\n}\n\n@media screen and (max-width: 899px) {\n    .header-main{\n        padding-left: 20px;\n    }\n}\n\n.hover {\n    border-bottom-width: 2px;\n    border-bottom-color:white;\n    padding-bottom: 5px;\n    cursor: pointer;\n\n\n  }\n  .pointer:hover{\n    cursor: pointer;\n  }\n\n@media screen and (min-width: 900px) {\n    .header-main{\n        display: flex;\n        flex-direction: row;\n    }   \n    .routes{\n        display: flex;\n        flex-direction: row;\n\n    }\n\n}\n\n.fixed-header{\n    position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 1001;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
