// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 899px) {

    .main-footer {
        padding: 20px;
        background-color: black;
    }
    .grow-text {
        margin-right: 20px;
        margin-left: 20px;
    }
    .service-list {
        padding-top: 50px;
    }
    .all-right-reserved {
        margin-right: 20px;
        margin-left: 20px;
    }
}

@media screen and (min-width: 900px) {
    .main-footer {
        display: flex;
        background-color: black;
    }
    .grow-text {
        margin-right: 60px;
        margin-left: 60px;
    }
    .service-list {
        padding-right: 162px;
    }
    .all-right-reserved {
        margin-right: 160px;
        margin-left: 160px;
        display: flex;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/styles.css"],"names":[],"mappings":"AAAA;;IAEI;QACI,aAAa;QACb,uBAAuB;IAC3B;IACA;QACI,kBAAkB;QAClB,iBAAiB;IACrB;IACA;QACI,iBAAiB;IACrB;IACA;QACI,kBAAkB;QAClB,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,aAAa;QACb,uBAAuB;IAC3B;IACA;QACI,kBAAkB;QAClB,iBAAiB;IACrB;IACA;QACI,oBAAoB;IACxB;IACA;QACI,mBAAmB;QACnB,kBAAkB;QAClB,aAAa;IACjB;AACJ","sourcesContent":["@media screen and (max-width: 899px) {\n\n    .main-footer {\n        padding: 20px;\n        background-color: black;\n    }\n    .grow-text {\n        margin-right: 20px;\n        margin-left: 20px;\n    }\n    .service-list {\n        padding-top: 50px;\n    }\n    .all-right-reserved {\n        margin-right: 20px;\n        margin-left: 20px;\n    }\n}\n\n@media screen and (min-width: 900px) {\n    .main-footer {\n        display: flex;\n        background-color: black;\n    }\n    .grow-text {\n        margin-right: 60px;\n        margin-left: 60px;\n    }\n    .service-list {\n        padding-right: 162px;\n    }\n    .all-right-reserved {\n        margin-right: 160px;\n        margin-left: 160px;\n        display: flex;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
